import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MyAccountService } from '../services/my-account.service';
import {  NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {

  public order_id;
  public order; 

  constructor(
    private router: ActivatedRoute,
    private account: MyAccountService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.router.params.subscribe((params:any) => {
      this.order_id = params.id;
      if(this.order_id) {
        this.getOrder();
      }
    });
  }

  getOrder() {
    this.spinner.show();
    let params = 'order_id=' + this.order_id;
    this.account.getOrder(params).subscribe((res:any) => {
      if(res.status) {
        this.spinner.hide();
        this.order = res.order;
      } else {
        this.spinner.hide(); 
      }
    });
  }

}
