import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { NgForm } from '@angular/forms';
import { MessageBarComponent } from '../common-component/message-bar/message-bar.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar,MAT_SNACK_BAR_DATA, MatSnackBarConfig } from '@angular/material';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  public token:string;
  
  constructor(
    private route: ActivatedRoute,
    private user: UserService,
    public snackBar: MatSnackBar,
    private router: Router,
    ) { }

  ngOnInit() {
    this.route.params.subscribe( params => {
      if (params.token) { 
          this.token = params.token;
          this.verifyResetPasswordToken();        
      }
  });
  }

  verifyResetPasswordToken() { 
        var th = this;
        this.user.verifyResetToken(this.token).subscribe((response: any) => {
          if (!response.status) {
            let config = new MatSnackBarConfig();
            config.duration = 7000;
            config.panelClass = ['red-snackbar'];
            config.verticalPosition = 'top';
            config.data = "Something went be wrong, please try later.";
            th.snackBar.openFromComponent(MessageBarComponent, config);
            this.router.navigate([""]);
          } 
    
    
        });
      }

  resetPassword(form: NgForm) {
    var th=this;  
    this.user.resetPassword(this.token, form.value).subscribe(function (res:any) {
        if(res.status){
            form.reset();
            let config = new MatSnackBarConfig();
            config.duration = 7000;
            config.panelClass = ['green-snackbar'];
            config.verticalPosition = 'top';
            config.data = "Password has reset successfully. Now you can login with new password.";
            th.snackBar.openFromComponent(MessageBarComponent, config);
            th.router.navigate([""]);
        } else if(!res.status) {
          let config = new MatSnackBarConfig();
          config.duration = 7000;
          config.panelClass = ['red-snackbar'];
          config.verticalPosition = 'top';
          config.data = "Something went be wrong, please try later.";
          th.snackBar.openFromComponent(MessageBarComponent, config);
        }
    });
}

}
