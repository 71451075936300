import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { server_conf } from '../../app.config';
import { Category } from '../../interface/category';
import { NgForm } from '@angular/forms';
import { SuncribeService } from '../../services/suncribe.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar,MAT_SNACK_BAR_DATA, MatSnackBarConfig } from '@angular/material';
import { MessageBarComponent } from '../../common-component/message-bar/message-bar.component';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public adminLocation;
  public adminSettings;
  public address = '4A/15- 19 Henry St, Loganholme QLD 4129';
  public lat;
  public lng;
  public phonenumber = '877-426-1500';
  public emailId = 'sales@tradezy.io';
  public message;
  public error = false;

  constructor( 
    private http: HttpClient,
    private subscription: SuncribeService,
    public snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
  ) { }

  host = server_conf.host;
  home_url = server_conf.server;
  categories: Category;

  ngOnInit() {
  	this.http.get<Category>(this.host+'getallcategory').subscribe(categories => {
  		if(categories.status){
        this.categories = categories.categories;
      }
  	}, err =>{
  		console.log('Server erroe Occured!');
    });
    
    this.getLocationDetails();
  }

  getLocationDetails() {
    this.http.get(this.host+"get-location").subscribe((res:any) => {
			if(res.status){
				this.adminLocation = res.data.location;
				this.adminSettings = res.data.admin_settings;
				if(this.adminLocation){
					this.address = this.adminLocation.location;
					this.lat = this.adminLocation.latitude;
					this.lng = this.adminLocation.longitude;
				}
				if(this.adminSettings){
					this.phonenumber = this.adminSettings.phone;
					this.emailId = this.adminSettings.email;
				}
			}
		})
  }

  usersubscribe(form:NgForm) {
    var th=this;    
    th.spinner.show();
    this.subscription.subscribe_user(form.value).subscribe(function (res:any) {
        if(res.status){
          th.spinner.hide();
            form.reset();
            let config = new MatSnackBarConfig();
                config.duration = 7000;
                config.panelClass = ['green-snackbar'];
                config.verticalPosition = 'top';
                config.data = res.msg;
                th.snackBar.openFromComponent(MessageBarComponent, config);
        } else {
          th.spinner.hide();
            th.message=res.errors.email;
            th.error=true;
        }
    });
  }

}
