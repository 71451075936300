import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { server_conf } from '../app/app.config';
//import { Location } from '../app/interface/location';
import {  HttpClient,  HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(
    private http: HttpClient,
  ) { }

  public currencies;

  location = new Subject();


  private currencySource = new BehaviorSubject({
    selectedCurrency: null,
    current_currency_ob: null
  });

  currentCurrency = this.currencySource.asObservable();

  getLocation(currencies) {
    this.currencies = currencies;
    let country_found = false;
    let _return = {};
  //  if (navigator.geolocation) {
      // navigator.geolocation.getCurrentPosition((position) => {
      //   this.http.get<Location>(server_conf.host + 'fetch-user-country-from-lat-lng?lat='+position.coords.latitude+'&lng='+position.coords.longitude).subscribe(response => {
      //     if(response.status){
      //       for(let current_currency of this.currencies){
      //         if(current_currency.country == response.data.country || current_currency.country_code == response.data.country_code){
      //           country_found = true;
      //           this.currencySource.next({
      //             selectedCurrency: `${current_currency.symbol_left} ${current_currency.title}`,
      //             current_currency_ob: current_currency
      //           });
      //         }
      //       }
      //
      //       if(!country_found) {
      //         for(let current_currency of this.currencies){
      //           if(current_currency.is_default){
      //             this.currencySource.next({
      //               selectedCurrency: `${current_currency.symbol_left} ${current_currency.title}`,
      //               current_currency_ob: current_currency
      //             });
      //           }
      //         }
      //       }
      //
      //       return _return;
      //
      //     } else {
      //       for(let current_currency of this.currencies){
      //         if(current_currency.is_default){
      //           this.currencySource.next({
      //             selectedCurrency: `${current_currency.symbol_left} ${current_currency.title}`,
      //             current_currency_ob: current_currency
      //           });
      //         }
      //       }
      //
      //     }
      //   });
      // }, (error) => {
      //   for(let current_currency of this.currencies){
      //     if(current_currency.is_default){
      //       this.currencySource.next({
      //         selectedCurrency: `${current_currency.symbol_left} ${current_currency.title}`,
      //         current_currency_ob: current_currency
      //       });
      //     }
      //   }
      // });
    //} else {
      for(let current_currency of this.currencies) {
        if(current_currency.is_default) {
          this.currencySource.next({
            selectedCurrency: `${current_currency.symbol_left} ${current_currency.title}`,
            current_currency_ob: current_currency
          });
        }
      }
    //}
  }
}
