import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../services/notification.service';
import {  AuthService} from '../services/auth.service';
import {MatCardModule} from '@angular/material/card';
import {  Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  public notifications : Array<any> = [];
  public user = this.auth.getUser();
  public next_page_url;
  public isDisabled = true;

  constructor( 
    private notification: NotificationService,
    private auth:AuthService,
    private route: Router,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.updateNotificationStatus();
    let params = 'user_id='+this.user.id;
    this.spinner.show();
    this.notification.getUnseenNotifications(params).subscribe((res:any) => {
      if(res.status) {
        this.spinner.hide();
        this.notifications = res.notifications.data;
        this.next_page_url = res.notifications.next_page_url;
      } else {
        this.spinner.hide();
      }
    });
  }

  loadMoreNotifications() {
    if(this.next_page_url) {
      this.spinner.show();
      let params = '&user_id='+this.user.id;
      let temp = this.next_page_url.split('?');
      temp[1] += params;
      this.notification.getUnseenNotifications(temp[1]).subscribe((res:any) => {
        if(res.status) {
          this.spinner.hide();
          var tempArr = [];
          for (var i = 0; i < this.notifications.length; i++) {
						tempArr.push(this.notifications[i]);
					}
					for (var i = 0; i < res.notifications.data.length; i++) {
						tempArr.push(res.notifications.data[i]);
					}
					this.notifications = tempArr;
					this.next_page_url = res.notifications.next_page_url;
        } else {
          this.spinner.hide();
        }
      });
    }
  }

  updateNotificationStatus() {
    let params = 
    {
      user_id : this.user.id
    }
    this.notification.updateNotificationStatus(params).subscribe((res:any) => {
      if(res.status) {
        this.notification.notificationArray.next([]);
        this.notification.notArr = [];
        this.notification.notificationTotal.next(0);
        this.notification.notificationCount = 0;
      } else {
        
      }
    });
  }

  viewNotification(index, slug) {
    if(slug == 'new-product') {
      let data = this.notifications[index].product_slug;
      this.route.navigate(["/product/", data]);
    } else if(slug == 'transaction') {
      let data = this.notifications[index].order_id;
      this.route.navigate(["/my-account/order-details/", data]);
    } else {
      let data = this.notifications[index].order;
      this.route.navigate(["/my-account/order-details/", data]);      
    }
  }

}
