import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RouteList } from './routerlist';



/*const routes: Routes = RouteList;*/

@NgModule({
  imports: [RouterModule.forRoot(RouteList)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
