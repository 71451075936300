import { Component, OnInit } from '@angular/core';
import { Pages } from '../interface/pages';
import { HttpClient } from '@angular/common/http';
import { server_conf } from '../app.config';

@Component({
  selector: 'app-refund-policy',
  templateUrl: './refund-policy.component.html',
  styleUrls: ['./refund-policy.component.css']
})
export class RefundPolicyComponent implements OnInit {

  constructor(private http: HttpClient) { }
  host = server_conf.host;
  home_url = server_conf.server;
  pages: Pages;
  content = '';
  ngOnInit(): void {
  	
  	this.http.get<Pages>(this.host+"cms/refund-policy").subscribe( pages =>{
  		if(pages.status){
  			this.content = pages.page.content_text;
  		}
  		else{
  			
  		}
  	}, err =>{
		
  	});

  }


}
