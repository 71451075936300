import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MessageService } from './message.service';
import { server_conf } from '../app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MyAccountService {

	private baseURL = server_conf.host;
	private aussie_url = server_conf.ausie_digital;
	private accessToken = localStorage.getItem('LoggedInUser');
	constructor(private http: HttpClient, private messageService: MessageService) {}

	storeSetupRequest(params) {

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + this.accessToken
			 })
		};
		return this.http.post(this.baseURL + 'store-request', params, httpOptions);
	}


	getUserDetails(params) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + this.accessToken
			 })
		};
		return this.http.get(this.baseURL + 'get-user-details?'+params, httpOptions);
	}

	saveUserDetails(params) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + this.accessToken
			 })
		};
		return this.http.post(this.baseURL + 'save-user-details', params, httpOptions);
	}

	changePassword(user) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + this.accessToken
			 })
		};
		return this.http.post(this.baseURL + 'change-password', user, httpOptions);
	}

	getCountries() {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + this.accessToken
			})
		};
		return this.http.get(this.baseURL + 'get-countries', httpOptions);
	}

	getStates(country_id) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + this.accessToken
			})
		};
		return this.http.get(this.baseURL + 'get-states/' + country_id, httpOptions);
	}

	addAddress(user) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + this.accessToken
			 })
		};
		return this.http.post(this.baseURL + 'save-address', user, httpOptions);
	}

	getAddresses(user_id) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + this.accessToken
			})
		};
		return this.http.get(this.baseURL + 'get-addresses?user_id=' + user_id, httpOptions);
	}

	getUserAddress(user_id, address_id) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + this.accessToken
			})
		};
		return this.http.get(this.baseURL + 'get-user-address?user_id=' + user_id +'&address_id=' + address_id, httpOptions);
	}

	editAddress(user) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + this.accessToken
			 })
		};
		return this.http.post(this.baseURL + 'update-address', user, httpOptions);
	}

	deleteAddress(user) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + this.accessToken
			 })
		};
		return this.http.post(this.baseURL + 'delete-address', user, httpOptions);
	}

	getWalletAddress(user_id) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + this.accessToken
			 })
		};
		return this.http.get(this.baseURL + 'get-wallet-address?user_id='+user_id, httpOptions);
	}

	sendOTP(wallet_address) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
			 })
		};
		return this.http.post(this.aussie_url+'gateway/send_otp', wallet_address);
	}

	verifyOTP(params) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
			 })
		};
		return this.http.post(this.aussie_url+'gateway/verify_otp', params);
	}

	saveWalletAddress(params) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + this.accessToken
			 })
		};
		return this.http.post(this.baseURL + 'save-wallet-address', params, httpOptions);
	}

	getWalletBalance(params) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
			 })
		};
		return this.http.post(this.aussie_url+'gateway/balance', params);
	}

	getOrders(params) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + this.accessToken
			 })
		};
		return this.http.get(this.baseURL + 'get-orders?' + params, httpOptions);
	}

	returnOrderItem(params) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + this.accessToken
			 })
		};
		return this.http.post(this.baseURL + 'return-order-item', params, httpOptions);
	}

	cancelOrderItem(params) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + this.accessToken
			 })
		};
		return this.http.post(this.baseURL + 'cancel-order-item', params, httpOptions);
	}

	getOrder(params) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + this.accessToken
			 })
		};
		return this.http.get(this.baseURL + 'get-order?' + params, httpOptions);
	}

	paypalPayment(params){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + this.accessToken
			 })
		};
		return this.http.post(this.baseURL + 'order/paypal-payment',params,httpOptions);
	}

	audPayment(params){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + this.accessToken
			 })
		};
		return this.http.post(this.baseURL + 'aud-order',params,httpOptions);
	}
	priceToTokenConversion(params){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + this.accessToken
			 })
		};
		return this.http.post(this.baseURL + 'currency/convert-in-token',params,httpOptions);
	}

}
