import {  Component,  OnInit,  Inject} from '@angular/core';
import {  HttpClient,  HttpHeaders} from '@angular/common/http';
import {  Category } from '../../interface/category';
import { Currencies } from '../../interface/currencies';
import {  UserService} from '../../services/user.service';
import {  AuthService} from '../../services/auth.service';
import {  server_conf} from '../../app.config';
import {  NgForm} from '@angular/forms';
import {  MatDialog,  MatDialogRef,  MAT_DIALOG_DATA,  MatSnackBar,  MAT_SNACK_BAR_DATA,  MatSnackBarConfig} from '@angular/material';
import {  MessageBarComponent} from '../message-bar/message-bar.component';
import {  Router} from '@angular/router';
import {  NgxSpinnerService} from 'ngx-spinner';
import {  CartService} from '../../services/cart.service';
import { NotificationService } from '../../services/notification.service';
import {  Subject} from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { CurrencyChangeService } from '../../services/currency-change.service';
import { LocationService } from '../../location.service';
import { Location } from '@angular/common';
import { PushNotificationService } from '../../services/pushnotification.service';
import { MyAccountService } from '../../services/my-account.service';

// Header component
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {



  show: boolean = true;

  show_submenu: boolean = true;

  categories: Category;
  isHovMenuClose = false;
  host = server_conf.host;
  isLoggedIn: boolean = false;
  public cartTotal = 0;
  public user_name;
  public search: Subject < string > = new Subject;
  public keyword;
  public notificationCount = 0;
  public notifications : Array<any> = [];
  public currencies : Array<any> = [];
  public store_available = true;
  // public selected = 0;

  selectedCurrency = `₹ Indian Rupee`;
  public showHide = true;

  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    private auth: AuthService,
    private myRoute: Router,
    private cart: CartService,
    private notification: NotificationService,
    private currency: CurrencyChangeService,
    private locationService: LocationService,
    private location: Location,
    private spinner: NgxSpinnerService,
    private pusher: PushNotificationService
  ) {

  }

  ngOnInit(): void {

    this.pusher.watchForNotifications();

    if(window.localStorage.getItem('selected_currency') != null){
      const v = this.currency.getSelectedCurrencyObj();
      this.selectedCurrency = `${v.symbol_left} ${v.title}`;
    }

    this.http.get <Currencies> (this.host + 'getcurrencies').subscribe(response => {
      if (response.status) {
        this.currencies = response.currencies;
        console.log(response.currencies);

        if(window.localStorage.getItem('selected_currency') == null){

          let currency_selected = false;
          for(let current_currency of response.currencies){
            if(current_currency.code == response.user_currency){
              currency_selected = true;
              this.selectedCurrency = `${current_currency.symbol_left} ${current_currency.title}`;
              this.currency.emit(JSON.stringify(current_currency));
            }
          }

          if (!currency_selected) {
            for(let current_currency of response.currencies){
              if(current_currency.is_default){
                currency_selected = true;
                this.selectedCurrency = `${current_currency.symbol_left} ${current_currency.title}`;
                this.currency.emit(JSON.stringify(current_currency));
              }
            }
          }


         this.locationService.getLocation(this.currencies);
         this.locationService.currentCurrency.subscribe(data => {
           if(data.selectedCurrency !== null){
            this.selectedCurrency = data.selectedCurrency;
            this.currency.emit(JSON.stringify(data.current_currency_ob));
           }
         });
        }

      }
    }, err => {
      console.log('Server not found!');
    });

    this.auth.isLogged.subscribe(res => {
      this.isLoggedIn = res;
      if (this.isLoggedIn) {
        this.auth.user_name.subscribe((res: string) => {
          this.user_name = res;
        });
      }

      this.notification.notificationTotal.subscribe((res:number) => {
        this.notificationCount = res;
      });

      this.notification.notificationArray.subscribe((res:any) => {
        this.notifications = res;
      });

      this.countUnseenNotification();
      this.getUnseenNotifications();

      this.cart.cartTotal.subscribe((res: number) => {
        this.cartTotal = res;
      });
      this.getCartTotal();
    });

    this.http.get < Category > (this.host + 'getallcategory').subscribe(categories => {
      if (categories.status) {
        console.log("Categories "+JSON.stringify(categories));
        this.categories = categories.categories;
      }
    }, err => {
      console.log('Server not found!');
    });
  }

  compareObjects(obj){
    return obj.code == this.currency.getSelectedCurrency();
  }

  setCurrency(value) {
    this.spinner.show();
    this.currency.emit(value);
    this.showHide = false;
    this.selectedCurrency = `${value.symbol_left} ${value.title}`;
    location.reload();
  }



  getUnseenNotifications() {
    if (this.auth.getUser()) {
      let params = 'user_id='+this.auth.getUser().id+'&unseen=true';
      this.notification.getUnseenNotifications(params).subscribe((res:any) => {
        if(res.status) {
          this.notifications = res.notifications.data;
          if(this.notifications.length) {
            //this.notifications = this.notifications.splice(0,5);
            this.notification.notArr = this.notifications;
            this.notification.notificationArray.next(this.notification.notArr);
          }
        }
      });
    }

  }

  add3Dots(string, limit) {
    var dots = "...";
    if(string.length > limit) {
      // you can also use substr instead of substring
      string = string.substring(0,limit) + dots;
    }

    return string;
  }

  viewNotification(index, slug) {
    if(slug == 'new-product') {
      let data = this.notifications[index].product_slug;
      this.myRoute.navigate(["/product/", data]);
    } else if(slug == 'transaction') {
      let data = this.notifications[index].order_id;
      this.myRoute.navigate(["/my-account/order-details/", data]);
    } else {
      let data = this.notifications[index].order;
      this.myRoute.navigate(["/my-account/order-details/", data]);
    }
  }

  isResMenu = false;



  SignInPopupId() {
    const dialogRef = this.dialog.open(SignInPopup, {
      width: '850px'
    });
  }

  SignUpPopupId() {
    const dialogRef = this.dialog.open(SignUpPopup, {
      width: '850px'
    });
  }

  SignOut() {
    this.auth.logout();
    this.myRoute.navigate([""]);
    this.cart.cartTotal.next(0);
    this.cart.cartCount = 0;
    this.notification.notificationTotal.next(0);
    this.notification.notificationCount = 0;
  }

  getCartTotal() {

    if (this.auth.getUser()) {
      let params = 'user_id=' + this.auth.getUser().id;

      let temp = [];

      this.cart.getCart(params).subscribe((res: any) => {
        if (res.status) {
          temp = res.carts;
          this.cart.cartTotal.next(temp.length);
          this.cart.cartCount = temp.length;
        }
      });
    }
  }

  countUnseenNotification() {
    if (this.auth.getUser()) {
      this.notification.countUnseenNotification(this.auth.getUser().id).subscribe((res:any) => {
        if(res.status) {
          this.notification.notificationTotal.next(res.count);
          this.notification.notificationCount = res.count;
        }

      });
    }

  }

  onSearch(form: NgForm) {
    let keyword = form.value;
    this.keyword = keyword.keyword;
    this.search.next(this.keyword);
    this.myRoute.navigate(["/products"], {
      queryParams: {
        keyword: keyword.keyword
      }
    });
  }
}


// SignIn Popup component
@Component({
  selector: 'sign-in-popup',
  templateUrl: './sign-in-popup.html',
  styleUrls: ['./sign-in-popup.css']
})

export class SignInPopup implements OnInit {
  isForgotPassowrd = false;
  host = server_conf.site_url;
  public notRedirect = false;

  public login_email;
  public login_password;

  public login_remember_me = false;

  ngOnInit(): void {
    this.login_email = this.cookieService.check('email') ? this.cookieService.get('email') : '';
    this.login_password = this.cookieService.check('password') ? this.cookieService.get('password') : '';
    this.login_remember_me = this.cookieService.check('remember_me') ? true : false;
  }


  constructor(
    public dialog: MatDialog,
    private user: UserService,
    private auth: AuthService,
    public snackBar: MatSnackBar,
    private myRoute: Router,
    public dialogRef: MatDialogRef < SignInPopup > ,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cart: CartService,
    private cookieService: CookieService,
    private notification: NotificationService
  ) {
    if (this.data && this.data.notRedirect) {
      this.notRedirect = this.data.notRedirect;
    }
  }


  SignUpPopupId() {
    const dialogRef = this.dialog.open(SignUpPopup, {
      width: '850px'
    });
  }


  ForgotPassword(form: NgForm) {
    var th = this;
    let data = {
      "email": form.value['user_email']
    };
    data['site_url'] = this.host;
    th.spinner.show();
    this.user.forgotPassword(data).subscribe(function (res: any) {
      if (res.status) {
        th.spinner.hide();
        form.reset();
        th.dialogRef.close();
        let config = new MatSnackBarConfig();
        config.duration = 7000;
        config.panelClass = ['green-snackbar'];
        config.verticalPosition = 'top';
        config.data = "Reset Password link has sent to your email address. Please check your email.";
        th.snackBar.openFromComponent(MessageBarComponent, config);
      } else {
        th.spinner.hide();
        let config = new MatSnackBarConfig();
        config.duration = 7000;
        config.panelClass = ['red-snackbar'];
        config.verticalPosition = 'top';
        config.data = "Something went wrong, please try later.";
        th.snackBar.openFromComponent(MessageBarComponent, config);
      }
    });
  }

  //User Login
  Login(form: NgForm) {
    // From data
    let data = form.value;
    this.spinner.show();
    // Call login API
    this.user.login_user(data).subscribe((result: any) => {
      // if status success then login
      if (result.status) {

        if(form.value.remember_me) {
          this.cookieService.set( 'email', form.value.email );
          this.cookieService.set( 'password', form.value.password );
          this.cookieService.set( 'remember_me', 'true', 0, '/');
        } else if(!form.value.remember_me) {
          this.cookieService.delete('email');
          this.cookieService.delete('password');
          this.cookieService.delete('remember_me');
        }

        // reset login status
        this.auth.resetLogin();
        // set user access token
        this.auth.setUser(result.acessToken.accessToken);
        // set user information
        localStorage.setItem("user", JSON.stringify(result.user));

        if (this.auth.getUser()) {
          this.auth.user_name.next(this.auth.getUser().full_name);
        }

        // signIn Form fields reset
        form.resetForm();
        // close signIn Form
        this.dialogRef.close();
        // Show Message
        let config = new MatSnackBarConfig();
        config.duration = 7000;
        config.panelClass = ['green-snackbar'];
        config.verticalPosition = 'top';
        config.data = "Login successfully.";
        this.snackBar.openFromComponent(MessageBarComponent, config);

        //this.getAfterLoginNotifications();
        this.getCartTotal();


        if(result.user.store_id != ''){
          this.myRoute.navigate(["/my-account/dashboard"]);
        }else{
        //If user click on add to cart button
        if (!this.notRedirect) {
          // Navigate to the user account
          this.myRoute.navigate(["/my-account"]);
        }
      }


      } else {
        // else show error messages
        let msg = "";
        if (result.message.email) {
          msg = result.message.email;
        } else if (result.message) {
          msg = result.message;
        } else {
          msg = "Something went be wrong, Please try later."
        }
        let config = new MatSnackBarConfig();
        config.duration = 7000;
        config.panelClass = ['red-snackbar'];
        config.verticalPosition = 'top';
        config.data = msg;
        this.snackBar.openFromComponent(MessageBarComponent, config);
      }
      this.spinner.hide();

    });
  }

  getCartTotal() {

    if (this.auth.getUser()) {
      let params = 'user_id=' + this.auth.getUser().id;
      let token = localStorage.getItem('LoggedInUser');

      let temp = [];

      this.cart.getCartAferLogin(params, token).subscribe((res: any) => {
        if (res.status) {
          temp = res.carts;
          this.cart.cartTotal.next(temp.length);
          this.cart.cartCount = temp.length;
        }
      });
    }
  }

  getAfterLoginNotifications() {
    if (this.auth.getUser()) {
      let params = 'user_id=' + this.auth.getUser().id+'&unseen=true';
      let token = localStorage.getItem('LoggedInUser');

      this.notification.countAfterLoginNotification(this.auth.getUser().id, token).subscribe((res:any) => {
        if(res.status) {
          this.notification.notificationTotal.next(res.count);
          this.notification.notificationCount = res.count;
        }
      });

      // this.notification.getNotificationAferLogin(params, token).subscribe((res: any) => {
      //   if (res.status) {
      //     let notifications = res.notifications.data;
      //     if(notifications.length) {
      //       notifications = notifications.splice(0,5);
      //       this.notification.notArr = notifications;
      //       this.notification.notificationArray.next(this.notification.notArr);
      //     }
      //   }
      // });


    }
  }

  displayStorageEvent() {
    console.log(localStorage.getItem('aussie_digital_user'));
  }

  signInWithAussieDigital() {
    const dialogRef = this.dialog.open(AussieDigitalLoginPopup, {
      width: '650px',
      data: {
        notRedirect: this.notRedirect ? true : false
      }
    });
  //   console.log(localStorage.getItem('aussie_digital_user'));
  //   var win = window.open(this.host+'/aussie-digital-login',"_blank", "width=1280,height=500");
  //   console.log(win);

  //   win.addEventListener('unload', this.HandlePopupResult);
  //   // win.onended(event).then(data => {
  //   //   console.log("hwrwrwr");
  //   //   console.log(data);
  //   // })
  }
}

//AussieDigitalLoginPopup component
@Component({
  selector: 'aussie-digital-login-popup',
  templateUrl: './aussie-digital-login-popup.html',
  styleUrls: ['./aussie-digital-login-popup.css']
})

export class AussieDigitalLoginPopup {
  public notRedirect = false;
  constructor(
    private user: UserService,
    public dialogRef: MatDialogRef < AussieDigitalLoginPopup > ,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private auth: AuthService,
    private cart: CartService,
    private notification: NotificationService,
    private myRoute: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (this.data && this.data.notRedirect) {
      this.notRedirect = this.data.notRedirect;
    }
  }

  postLogin(form:NgForm) {
    let data = form.value;
    this.spinner.show();
    this.user.aussieDigitalLogin(data).subscribe((res:any) => {
      if(res.status) {
        let _data = {
          email : res.data.user.email,
          password : data.password,
          aussie_digital_token : res.data.user.market_place_token,
          full_name : res.data.user.name
        }
        this.user.userAussieDigitalLogin(_data).subscribe((res:any) => {
          if(res.status) {
            this.spinner.hide();
            // reset login status
            this.auth.resetLogin();
            // set user access token
            this.auth.setUser(res.acessToken.accessToken);
            // set user information
            localStorage.setItem("user", JSON.stringify(res.user));

            if (this.auth.getUser()) {
              this.auth.user_name.next(this.auth.getUser().full_name);
            }

            // signIn Form fields reset
            form.resetForm();
            // close signIn Form
            this.dialog.closeAll();
            // Show Message
            let config = new MatSnackBarConfig();
            config.duration = 7000;
            config.panelClass = ['green-snackbar'];
            config.verticalPosition = 'top';
            config.data = "Login successfully.";
            this.snackBar.openFromComponent(MessageBarComponent, config);

            //this.getAfterLoginNotifications();
            this.getCartTotal();


            //If user click on add to cart button
            if (!this.notRedirect) {
              // Navigate to the user account
              this.myRoute.navigate(["/my-account"]);
            }
          } else {
            this.spinner.hide();
            let msg = "";
            if (res.message.email) {
              msg = res.message.email;
            } else if (res.message) {
              msg = res.message;
            } else {
              msg = "Something went be wrong, Please try later."
            }
            let config = new MatSnackBarConfig();
            config.duration = 7000;
            config.panelClass = ['red-snackbar'];
            config.verticalPosition = 'top';
            config.data = msg;
            this.snackBar.openFromComponent(MessageBarComponent, config);
          }
        });
      } else {
        this.spinner.hide();
        let msg = res.message;
        let config = new MatSnackBarConfig();
        config.duration = 7000;
        config.panelClass = ['red-snackbar'];
        config.verticalPosition = 'top';
        config.data = msg;
        this.snackBar.openFromComponent(MessageBarComponent, config);
      }
    });
  }

  getCartTotal() {

    if (this.auth.getUser()) {
      let params = 'user_id=' + this.auth.getUser().id;
      let token = localStorage.getItem('LoggedInUser');

      let temp = [];

      this.cart.getCartAferLogin(params, token).subscribe((res: any) => {
        if (res.status) {
          temp = res.carts;
          this.cart.cartTotal.next(temp.length);
          this.cart.cartCount = temp.length;
        }
      });
    }
  }

  // getAfterLoginNotifications() {
  //   if (this.auth.getUser()) {
  //     let params = 'user_id=' + this.auth.getUser().id+'&unseen=true';
  //     let token = localStorage.getItem('LoggedInUser');

  //     this.notification.countAfterLoginNotification(this.auth.getUser().id, token).subscribe((res:any) => {
  //       if(res.status) {
  //         this.notification.notificationTotal.next(res.count);
  //         this.notification.notificationCount = res.count;
  //       }
  //     });

  //     this.notification.getNotificationAferLogin(params, token).subscribe((res: any) => {
  //       if (res.status) {
  //         let notifications = res.notifications.data;
  //         if(notifications.length) {
  //           notifications = notifications.splice(0,5);
  //           this.notification.notArr = notifications;
  //           this.notification.notificationArray.next(this.notification.notArr);
  //         }
  //       }
  //     });


  //   }
  // }
}


// SignUp popup component
@Component({
  selector: 'sign-up-popup',
  templateUrl: './sign-up.html',
  styleUrls: ['./sign-up.css']
})

export class SignUpPopup {
  public countries: Array<any> = [];
  public error = false;
  public success = false;
  public message: any = '';
  public success_message: string = '';
  host = server_conf.site_url;
  public notRedirect = false;
  public ShowPassport;
  public name;
  public store_available = true;
  constructor(
    private account: MyAccountService,
    private user: UserService,
    public dialogRef: MatDialogRef < SignUpPopup > ,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data && this.data.notRedirect) {
      this.notRedirect = this.data.notRedirect;
    }
  }

  checkStoreAvailability(event: any){

     this.name = event.target.value;

     this.user.is_store_available(this.name).subscribe((res: any) => {

       if(res.status){
         this.store_available=true;
       }else{
         this.store_available=false;
       }
     });

  }

  //added by vibhor
  ShowVendorForm(val){
    if(val.checked){
      this.ShowPassport=true;
      this.account.getCountries().subscribe((res:any) => {
        if(res.status){
          this.countries = res.countries;
          console.log(this.countries);
        }
      });
    }else{
          this.ShowPassport=false;
    }

  }
  Registration(form: NgForm) {
    var th = this;
    let data = form.value;
    this.spinner.show();
    data['site_url'] = this.host;
    this.user.register_user(data).subscribe((res: any) => {
      if (res.success) {
        //AussieDigital API
        let _data = {
          name: data.full_name,
          email_id: data.email,
          password: data.password
        }

        var user_id = res.user_id;
        var msg = res.msg;

        // console.log(res);
        // console.log(data);

        form.reset();
        th.dialogRef.close();
        let config = new MatSnackBarConfig();
        config.duration = 7000;
        config.panelClass = ['green-snackbar'];
        config.verticalPosition = 'top';
        config.data = msg;
        th.snackBar.openFromComponent(MessageBarComponent, config);


        /**
         *
        this.user.aussieDigitalRegistration(_data).subscribe((res:any) => {
          if(res.status){
            let _aussieData = {
              user_id : user_id,
              aussie_digital_token : res.data.user.market_place_token
            }
            this.user.updateaussieDigitalToken(_aussieData).subscribe((res:any) => {
              if(res.status) {
                form.reset();
                th.dialogRef.close();
                let config = new MatSnackBarConfig();
                config.duration = 7000;
                config.panelClass = ['green-snackbar'];
                config.verticalPosition = 'top';
                config.data = msg;
                th.snackBar.openFromComponent(MessageBarComponent, config);
              } else {
                let config = new MatSnackBarConfig();
                config.duration = 7000;
                config.panelClass = ['red-snackbar'];
                config.verticalPosition = 'top';
                config.data = "Something went be wrong.";
                th.snackBar.openFromComponent(MessageBarComponent, config);
              }
            });
          } else {
            let config = new MatSnackBarConfig();
            config.duration = 7000;
            config.panelClass = ['red-snackbar'];
            config.verticalPosition = 'top';
            config.data = "Something went be wrong.";
            th.snackBar.openFromComponent(MessageBarComponent, config);
          }

        });

        */

      } else {

        if ((res['status'] == false) && res['errors']['email']) {
          let config = new MatSnackBarConfig();
          config.duration = 7000;
          config.panelClass = ['red-snackbar'];
          config.verticalPosition = 'top';
          config.data = res['errors']['email'];
          th.snackBar.openFromComponent(MessageBarComponent, config);
        }
        else if((res['status'] == false) && res['errors']['password']){
          let config = new MatSnackBarConfig();
          config.duration = 7000;
          config.panelClass = ['red-snackbar'];
          config.verticalPosition = 'top';
          config.data = res['errors']['password'];
          th.snackBar.openFromComponent(MessageBarComponent, config);
        }
         else {
          let config = new MatSnackBarConfig();
          config.duration = 7000;
          config.panelClass = ['red-snackbar'];
          config.verticalPosition = 'top';
          config.data = "Something went wrong.";
          th.snackBar.openFromComponent(MessageBarComponent, config);
        }
      }
      this.spinner.hide();

    });
  }

  signInWithAussieDigital() {
    const dialogRef = this.dialog.open(AussieDigitalLoginPopup, {
      width: '650px',
      data: {
        notRedirect: this.notRedirect ? true : false
      }
    });
  }

  SignInPopupId() {
    const dialogRef = this.dialog.open(SignInPopup, {
      width: '850px'
    });
  }
}
