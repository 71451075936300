import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA, MatSnackBarConfig } from '@angular/material';
import { WishlistService } from '../../services/wishlist.service';
import { MessageBarComponent } from '../message-bar/message-bar.component';
import { NgxSpinnerService } from 'ngx-spinner';
// import { WishlistComponent } from '../../wishlist/wishlist.component';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.css']
})
export class ConfirmationPopupComponent implements OnInit {

  public items;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private auth: AuthService,
    private dialogRef: MatDialogRef<ConfirmationPopupComponent>,
    public snackBar: MatSnackBar,
    public wishlist: WishlistService,
    public spiner: NgxSpinnerService,
    // public wishlistComponent: WishlistComponent

  ) {
    this.items = JSON.parse(data); 
   }

  ngOnInit() {
  }

  deleteItem(itemName){
    if(itemName == "delete_wishlist"){
      this.spiner.show();
      this.wishlist.deleteUserWishlist(this.items.whishlist_id).subscribe((result: any) => {
      
      if(result.status){
        let config = new MatSnackBarConfig();
        config.duration = 7000;
        config.panelClass = ['green-snackbar'];
        config.verticalPosition = 'top';
        config.data = "Product successfully deleted from wishlist.";
        this.snackBar.openFromComponent(MessageBarComponent, config);
        
      }else{
        let config = new MatSnackBarConfig();
        config.duration = 7000;
        config.panelClass = ['red-snackbar'];
        config.verticalPosition = 'top';
        config.data = "Something went be wrong.";
        this.snackBar.openFromComponent(MessageBarComponent, config);
      }
      this.spiner.hide();
    });
    this.dialogRef.close();

    }
  }

}
