import { AboutComponent } from "../about/about.component";
import { PrivacyPolicyComponent } from "../privacy-policy/privacy-policy.component";
import { FaqComponent } from "../faq/faq.component";
import { RefundPolicyComponent } from "../refund-policy/refund-policy.component";
import { TermAndConditionComponent } from "../term-and-condition/term-and-condition.component";
import { ActivateUserComponent } from "../activate-user/activate-user.component";
import { AuthGuard } from "../_guards/auth.guard";
import { ShoppingCartComponent } from "../shopping-cart/shopping-cart.component";
import { ResetPasswordComponent } from "../reset-password/reset-password.component";
import { NotificationComponent } from "../notification/notification.component";
import { NotFoundComponent } from "../not-found/not-found.component";
import { InvoiceComponent } from "../invoice/invoice.component";
import { AussieDigitalLoginComponent } from "../aussie-digital-login/aussie-digital-login.component";

import { BecomeASellerComponent } from "../become-a-seller/become-a-seller.component";



export const RouteList = [
  { path: "", loadChildren: "../home/home.module#HomeModule" },
  {
    path: "invoice/:id",
    component: InvoiceComponent,
    canActivate: [AuthGuard],
  },
  { path: "about-us", component: AboutComponent },
  { path: "privacy-policy", component: PrivacyPolicyComponent },
  {
    path: "contact-us",
    loadChildren: "../contact/contact.module#ContactModule",
  },
  {
    path: "product/:productslug",
    loadChildren: "../product-detail/product-detail.module#ProductDetailModule",
  },
  {
    path: "products",
    loadChildren: "../product-list/product-list.module#ProductListModule",
  },
  {
    path: "category/:categoryslug",
    loadChildren: "../product-list/product-list.module#ProductListModule",
  },
  {
    path: "wishlist",
    loadChildren: "../wishlist/wishlist.module#WishlistModule",
    canActivate: [AuthGuard],
  },
  {
    path: "my-account",
    loadChildren: "../my-account/my-account.module#MyAccountModule",
    canActivate: [AuthGuard],
  },
  { path: "faq", component: FaqComponent },
  { path: "term-and-condition", component: TermAndConditionComponent },
  { path: "refund-policy", component: RefundPolicyComponent },
  { path: "reset-password/:token", component: ResetPasswordComponent },
  {
    path: "shopping-cart",
    loadChildren: "../shopping-cart/shopping-cart.module#ShoppingCartModule",
    canActivate: [AuthGuard],
  },
  { path: "activate-account/:id", component: ActivateUserComponent },
  {
    path: "notifications",
    component: NotificationComponent,
    canActivate: [AuthGuard],
  },
  { path: "aussie-digital-login", component: AussieDigitalLoginComponent },
  {
    path: "country_admin/register",
    loadChildren:
      "../register-country-admin/register-country-admin.module#RegisterCountryAdminModule",
  },
  { path: "404", component: NotFoundComponent },
  {
    path: "become-a-seller",
    component: BecomeASellerComponent,
    canActivate: [AuthGuard],
  },
  { path: "**", redirectTo: "/404" },
];

export const routingComponents = [
  AboutComponent,
  PrivacyPolicyComponent,
  FaqComponent,
  RefundPolicyComponent,
  TermAndConditionComponent,
];
