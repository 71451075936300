import { Component, Inject } from '@angular/core';
import { MatNativeDateModule, MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatSnackBar,MAT_SNACK_BAR_DATA, MatSnackBarConfig } from '@angular/material';
import { NgForm } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { MyAccountService } from '../../services/my-account.service';
import { MessageBarComponent } from '../../common-component/message-bar/message-bar.component';

@Component({
	selector: 'delete-address-popup',
	templateUrl: './delete-address-popup.html',
	styleUrls: ['./delete-address-popup.css']
})

export class DeleteAddressPopup {

	private user_id;
	public address_id;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<DeleteAddressPopup>,
		private auth: AuthService,
		public snackBar: MatSnackBar,
		private account: MyAccountService,
	) {
		this.address_id = data.address_id;
		this.user_id = data.user_id;
	}

	confirmAddressDelete() {
		var th = this; 
		this.account.deleteAddress(this.data).subscribe((res:any) => {
			if(res.status) {
				this.dialogRef.close(res);
				let config = new MatSnackBarConfig();
				config.duration = 7000;
				config.panelClass = ['green-snackbar'];
				config.verticalPosition = 'top';
				config.data = 'Address has deleted successfully.';
				th.snackBar.openFromComponent(MessageBarComponent, config);
			} else {
				let config = new MatSnackBarConfig();
					config.duration = 7000;
					config.panelClass = ['red-snackbar'];
					config.verticalPosition = 'top';
					config.data = 'Something went wrong. Please try later.';
					th.snackBar.openFromComponent(MessageBarComponent, config);
			}
		});
	}
}