import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../services/user.service';
import {ISubscription} from "rxjs/Subscription";

@Component({
  selector: 'app-activate-user',
  templateUrl: './activate-user.component.html',
  styleUrls: ['./activate-user.component.css']
})
export class ActivateUserComponent implements OnInit {

  public message:string;
 
  constructor(private route: ActivatedRoute, private user: UserService) { }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');

    if(id){
      this.user.activate_user(id).subscribe((result:any) => {
        this.message = result.message;
      });
     
      
    }
  }

}
