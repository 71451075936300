import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { MessageService } from './message.service';
import { server_conf } from '../app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CartService {

	public cartTotal: Subject<number> = new Subject;
	public cartCount: number = 0;
	private baseURL = server_conf.host;
	private accessToken = localStorage.getItem('LoggedInUser');
	public selectedAddressID: number = 0;
	constructor(private http: HttpClient, private messageService: MessageService) {}

	addToCart(params) {
		const httpOptions = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': server_conf.server,
					'Authorization': 'Bearer ' + this.accessToken
				 })
			};
			return this.http.post(this.baseURL + 'save-cart', params , httpOptions);
	}

	getCartAferLogin(params, token) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + token
			 })
		};
		this.accessToken = token;
		return this.http.get(this.baseURL + 'get-cart?' + params, httpOptions);
	}

	getCart(params) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + this.accessToken
			 })
		};
		return this.http.get(this.baseURL + 'get-cart?' + params, httpOptions);
	}

	updateCartQuantity(params) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + this.accessToken
			 })
		};
		return this.http.post(this.baseURL + 'update-cart-quantity',params, httpOptions);
	}

	deleteCartItem(params) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + this.accessToken
			 })
		};
		return this.http.post(this.baseURL + 'delete-cart-item',params, httpOptions);
	}

	getCurrentYear(){
        const date = new Date();
        return date.getFullYear();
    }

	getNumbersOfArray(start:number = 1, till:number = 12, leadingZero = true){
        let numbers = [];

        if(start >= 1 ){

            for(let i = 0; i < till; i++){

                if(leadingZero){
                    numbers.push((i+start) <= 9 ? '0'+(i+start) : i+start);
                }else{
                    numbers.push(i+start);
                }
            }
        }

        return numbers;
	}

}
