import { HttpClient, HttpClientModule } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CurrencyChangeService } from "./services/currency-change.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private currency: CurrencyChangeService,
    private http: HttpClient
  ) {}

  isLoading = false;

  ngOnInit() {
    this.getIpAddress();
    this.getCategory();
    this.getProduct();
    this.getCurrencies();
    this.getAllads();
    this.getAllproducts();
    this.getBrands();
    this.getRelatedProducts();
    this.getProductCategory();
    this.getAllbanners();
    this.getFaqs();
    this.getProductReview();
    // this.getProductDetail();
    // this.getCms();
    // this.getCheckStoreAvailability();
    this.router.events.subscribe((val) => {
      window.scroll(0, 0);
    });

    this.currency.currentCurrency.subscribe((value) => {
      // console.log(value, 'value');
      this.isLoading = true;

      setTimeout(() => {
        this.isLoading = false;
      }, 100);
    });
  }

  showHeaderFooter() {
    let url = window.location.href;
    let arr = [];
    arr = url.split("/", url.length);

    if (arr.indexOf("invoice") > -1) {
      return false;
    } else {
      return true;
    }
  }

  getIpAddress() {
    this.http
      .get(
        "https://extreme-ip-lookup.com/json/?key=AIzaSyA-LCC-ocqBajZtOZToyppsEBl5iN3OFdQ&libraries"
      )
      .subscribe((res) => console.log("ipaddress =>", res));
  }

  getCategory() {
    this.http
    .get("https://marketplace.tradezy.io/api/getallcategory")
    .subscribe((res) => console.log(" category lsit", res));
  }

  getProduct(){
    this.http
    .get("https://marketplace.tradezy.io/api/getproducts")
    .subscribe((res) => console.log(" product lsit", res));
  }

  getCurrencies(){
    this.http
    .get("https://marketplace.tradezy.io/api/getcurrencies")
    .subscribe((res) => console.log(" currency lsit", res));
  }

  getAllads(){
    this.http
    .get("https://marketplace.tradezy.io/api/getallads")
    .subscribe((res) => console.log(" all ads lsit", res));
  }

  getAllproducts(){
    this.http
    .get("https://marketplace.tradezy.io/api/getallproducts")
    .subscribe((res) => console.log(" all products lsit", res));
  }

  getBrands(){
    this.http
    .get("https://marketplace.tradezy.io/api/get-brands")
    .subscribe((res) => console.log(" brand lsit", res));
  }

  getRelatedProducts(){
    this.http
    .get("https://marketplace.tradezy.io/api/get-related-products")
    .subscribe((res) => console.log(" related product lsit", res));
  }

  getProductCategory(){
    this.http
    .get("https://marketplace.tradezy.io/api/get_product_category")
    .subscribe((res) => console.log(" product category lsit", res));
  }

  getAllbanners(){
    this.http
    .get("https://marketplace.tradezy.io/api/getallbanners")
    .subscribe((res) => console.log(" all banners lsit", res));
  }

  getFaqs(){
    this.http
    .get("https://marketplace.tradezy.io/api/get-faqs")
    .subscribe((res) => console.log(" faqs lsit", res));
  }

  getProductReview(){
    this.http
    .get("https://marketplace.tradezy.io/api/get-product-review")
    .subscribe((res) => console.log(" product review lsit", res));
  }

  // getProductDetail(){
  //   this.http
  //   .get("https://marketplace.tradezy.io/api/product-detail/{slug}")
  //   .subscribe((res) => console.log(" product detail lsit", res));
  // }

  // getCms(){
  //   this.http
  //   .get("https://marketplace.tradezy.io/api/cms/{slug}")
  //   .subscribe((res) => console.log(" cms lsit", res));
  // }

  // getCheckStoreAvailability(){
  //   this.http
  //   .get("https://marketplace.tradezy.io/api/check_store_availability/{name}")
  //   .subscribe((res) => console.log(" check store availability lsit", res));
  // }
}
