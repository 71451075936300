import { Component, OnInit } from '@angular/core';
import { Pages } from '../interface/pages';
import { HttpClient } from '@angular/common/http';
import { server_conf } from '../app.config';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(private http: HttpClient) { }
  host = server_conf.host;
  pages:Pages;
  content = '';
  ngOnInit() {
  	this.http.get<Pages>(this.host+"cms/privacy-policy").subscribe(pages => {
  		if(pages.status){
  			this.content = pages.page.content_text;
  		}
  		else{
  			console.log('Data not found!');
  		}
  	}, err => {
		console.log('Server error occured!');
  	});
  }

}
