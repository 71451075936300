import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MessageService } from './message.service';
import { server_conf } from '../app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private baseURL = server_conf.host;
  private aussieURL = server_conf.ausie_digital;
	constructor(private http: HttpClient, private messageService: MessageService) {}
	register_user(user) {
		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': server_conf.server })
		};
		return this.http.post(this.baseURL + 'signup', user, httpOptions);
	}

  is_store_available(name) {
		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': server_conf.server })
		};
		return this.http.post(this.baseURL + 'check_store_availability/'+name, name, httpOptions);
	}

	forgotPassword(user) {
		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': server_conf.server })
		};
		return this.http.post(this.baseURL + 'forgot-password', user, httpOptions);
	}

	verifyResetToken(token) {
		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': server_conf.server })
		};
		return this.http.post(this.baseURL + 'verify-reset-token/'+token, token, httpOptions);
	}

	resetPassword(token, data) {
		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': server_conf.server })
		};
		return this.http.post(this.baseURL + 'reset-password/'+token, data, httpOptions);
	}

	login_user(user) {
		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': server_conf.server })
		};
		return this.http.post(this.baseURL + 'user-login', user, httpOptions);
	}

	activate_user(token) {
		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': server_conf.server })
		};
		return this.http.get(this.baseURL + 'activate-account/' + token, httpOptions);
	}

	aussieDigitalRegistration(params) {
		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': server_conf.server })
		};

		return this.http.post(this.aussieURL+'gateway/registration', params, httpOptions)
	}

	updateaussieDigitalToken(params) {
		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': server_conf.server })
		};
		return this.http.post(this.baseURL + 'update-aussie-digital-token', params, httpOptions);
	}

	aussieDigitalLogin(params) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': server_conf.server })
    };
		// const httpOptions = {
		// 	headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': server_conf.server })
		// };

		return this.http.post(this.aussieURL+'gateway/login', params, httpOptions)
	}

	userAussieDigitalLogin(params) {
		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': server_conf.server })
		};
		return this.http.post(this.baseURL + 'aussie-digital-login', params, httpOptions);
	}
}
