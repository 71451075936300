import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { MessageService } from './message.service';
import { server_conf } from '../app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

	public notificationTotal: Subject<number> = new Subject;
	public notificationCount: number = 0;
	public notificationArray: Subject<Array<any>> = new Subject;
	public notArr:Array<any> = [];
	private baseURL = server_conf.host;
	private accessToken = localStorage.getItem('LoggedInUser');
	constructor(private http: HttpClient, private messageService: MessageService) {}

	getNotificationAferLogin(params, token) {
		const httpOptions = {
			headers: new HttpHeaders({ 
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + token
				})
		};
		return this.http.get(this.baseURL + 'get-unseen-notifications?'+params , httpOptions);
	}

	countAfterLoginNotification(user_id, token) {
		const httpOptions = {
			headers: new HttpHeaders({ 
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + token
				})
		};
		return this.http.get(this.baseURL + 'count-unseen-notifications?user_id='+user_id , httpOptions);
	}

	countUnseenNotification(user_id) {
		const httpOptions = {
			headers: new HttpHeaders({ 
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + this.accessToken
				})
		};
		return this.http.get(this.baseURL + 'count-unseen-notifications?user_id='+user_id , httpOptions);
	}

	getUnseenNotifications(params) {
		const httpOptions = {
			headers: new HttpHeaders({ 
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + this.accessToken
				})
		};
		return this.http.get(this.baseURL + 'get-unseen-notifications?'+params , httpOptions);
	}

	updateNotificationStatus(params) {
		const httpOptions = {
			headers: new HttpHeaders({ 
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + this.accessToken
				})
		};
		return this.http.post(this.baseURL + 'update-notification-status', params , httpOptions);
	}

	manageNotificationSetting(params) {
		const httpOptions = {
			headers: new HttpHeaders({ 
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + this.accessToken
				})
		};
		return this.http.post(this.baseURL + 'manage-notification', params , httpOptions);
	}

	getNotificationSetting(params) {
		const httpOptions = {
			headers: new HttpHeaders({ 
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': server_conf.server,
				'Authorization': 'Bearer ' + this.accessToken
			 })
		};
		return this.http.get(this.baseURL + 'get-notification-setting?' + params, httpOptions);
	}

}
