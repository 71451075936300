import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MessageService } from './message.service';
import { server_conf } from '../app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
	providedIn: 'root'
})
export class SuncribeService {
	private baseURL = server_conf.host;
	constructor(private http: HttpClient, private messageService: MessageService) {}
	subscribe_user(user) {
		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': server_conf.server })
		};
		return this.http.post(this.baseURL + 'subscribe', user, httpOptions);
	}
}
