import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { server_conf } from 'src/app/app.config';
import { BrowserModule } from '@angular/platform-browser';

export class SubDomainInterceptor implements HttpInterceptor {

  constructor() {
    //console.log(window.location.host)
  }
  seller;
  currency_selected;
  headers;

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    //console.log(window.location.host);


    let subdomainUrl = window.location.host;
    if (subdomainUrl.split(".").length > 2) {
      //console.log(subdomainUrl);
      this.seller = subdomainUrl.split(".")[0];
    } else {
      this.seller = "home";
    }

    this.headers = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': server_conf.server, "country-id" : "231", "name" : "test"};

    if (window.localStorage.getItem("selected_currency") != null) {
      this.headers['Currency-Localization'] = window.localStorage.getItem("selected_currency");
    }

    const request = req.clone({
      setHeaders: {...this.headers},
      setParams: { store: this.seller }
    });
    return next.handle(request);
  }
}
