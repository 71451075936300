import { Injectable } from '@angular/core';
import { server_conf } from '../app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WishlistService {
  private baseURL = server_conf.host;
  constructor(private http: HttpClient) { }

  addToWishlist(whisItem) {
		const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json', 
      'Access-Control-Allow-Origin': server_conf.server,
      'Authorization': 'Bearer ' + localStorage.getItem("LoggedInUser"),
     })
    };
		return this.http.post(this.baseURL + 'save-wishlist', whisItem, httpOptions);
  }

  getUserWishlist(page = false) {
    let user = JSON.parse(localStorage.getItem("user"));

		const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json', 
      'Access-Control-Allow-Origin': server_conf.server,
      'Authorization': 'Bearer ' + localStorage.getItem("LoggedInUser"),
     })
    };
    let url = this.baseURL + 'get-wishlist?user_id=' + user.id;
    if(page) {
      url += '&'+page;
    }
		return this.http.get(url, httpOptions);
  }

  deleteUserWishlist(wishlistId) {
    let user = JSON.parse(localStorage.getItem("user"));
    let data = {
      "user_id": user.id,
      "wishlist_id": wishlistId
    };
		const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json', 
      'Access-Control-Allow-Origin': server_conf.server,
      'Authorization': 'Bearer ' + localStorage.getItem("LoggedInUser"),
     })
    };
		return this.http.post(this.baseURL + 'delete-wishlist',data, httpOptions);
  }

  checkWishlist(productId) {
    let user = JSON.parse(localStorage.getItem("user"));
    let data = {
      "user_id": user.id,
      "product_id": productId
    };
		const httpOptions = {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/json', 
      'Access-Control-Allow-Origin': server_conf.server,
      'Authorization': 'Bearer ' + localStorage.getItem("LoggedInUser"),
     })
    };
		return this.http.get(this.baseURL + 'check-product-wishlist?user_id='+user.id +'&product_id='+productId, httpOptions);
  }
  
}
