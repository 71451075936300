export const server_conf = {
  // live
  // ausie_digital :  'https://www.aussiedigital.io/' //live
  // host : 'http://marketplace.tradezy.io/api/', // live
  // server:'http://chatezy.io/', //live
  // site_url: 'http://chatezy.io/',  //live
  // socket_connection: 'wss://marketplace.tradezy.io/ws/',// live

  /* dev development */

  ausie_digital: "https://staging.aussiedigital.io/", //dev
  host: "https://marketplace.tradezy.io/api/", //dev
  //server: "http://chatezy.io/", //dev
  server: "https://marketplace.tradezy.io/", //dev
  //site_url: "http://chatezy.io/", //dev
  site_url: "https://marketplace.tradezy.io/", //dev
  socket_connection: "wss://tradezy.io:8080", //dev
  //socket_connection: "ws://marketplace.chatezy.io:8080", //dev

};
