import { Injectable } from '@angular/core';
import { MessageService } from './message.service';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { server_conf } from '../app.config';

declare var ab: any;

@Injectable()
export class PushNotificationService {

    /**
     * inform header component to show a notification message
     * @prop showNotification
     * @type Subject
     */
    public showNotification: Subject<any> = new Subject;

    constructor(
        private msgService: MessageService,
        private toastr: ToastrService
    ) { }
    

    /**
     * recieves push notification
     */
    watchForNotifications() {

        var classObj = this,
            conn = new ab.Session(server_conf.socket_connection,
                function () {
                    console.log('connected');
                    conn.subscribe('PurchasedProduct', function (topic, data) {
                        if(topic == 'PurchasedProduct') {
                            if(typeof(data.item_data) != 'undefined') {
                                let html = '<a href="/product/'+data.item_data.item_slug+'" target="_blank"> <img src="'+data.item_data.item_image+'" width="100" /> <div class="notification_content"> Someone has purchased '+data.item_data.item_name+' <span class="time"> '+data.item_data.purchased_ago+' </span> </div> </a>';
                                classObj.toastr.success( html, '', {
                                    enableHtml : true,
                                    positionClass : 'toast-bottom-left',
                                    closeButton: true,
                                    toastClass: 'ngx-toastr default-info',
                                    timeOut: 10000
                                });
                            }
                        }
                    });
                },
                function () {
                    console.warn('WebSocket connection closed');
                },
                { 'skipSubprotocolCheck': true }
            );
    }

}