import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CurrencyChangeService {

  constructor() { }


  currentCurrency = new Subject();

  emit(data) {
    data = JSON.parse(data);
    window.localStorage.setItem("selected_currency", data.id);
    window.localStorage.setItem("selected_currency_code", data.code);
    window.localStorage.setItem("selected_currency_obj", JSON.stringify(data));
    // this.currentCurrency.next(data);
    return true;

  }


  getSelectedCurrency() {
    if(window.localStorage.getItem("selected_currency_code")){
      return window.localStorage.getItem("selected_currency_code");
    } else {
      return 'USD';
    }
  }

  getSelectedCurrencyObj() {
    return JSON.parse(window.localStorage.getItem("selected_currency_obj"));
  }

}
