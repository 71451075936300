import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { server_conf } from '../app.config';
import { Pages } from '../interface/pages';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor( private http: HttpClient) { }
  host = server_conf.host;
  home_url = server_conf.server;
  pages: Pages;
  content = '';
  ngOnInit() {
  	this.http.get<Pages>(this.host+'cms/about-us').subscribe(pages=> {
  		if(pages.status){
  			this.pages = pages.page;
  			this.content = pages.page.content_text;

  		}
  		else{
  			
  		}
  	}, err => {
  		
  	});
  }

}
