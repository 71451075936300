import { Component, OnInit, Input} from '@angular/core';
// import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { Product } from '../interface/product';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar, MatSnackBarConfig, MatDialog } from '@angular/material';
import { MessageBarComponent } from '../common-component/message-bar/message-bar.component';
import { SignInPopup } from '../common-component/header/header.component';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import {ISubscription} from "rxjs/Subscription";
import { CartService } from '../services/cart.service';
import { server_conf } from '../app.config';
import { HttpClient } from '@angular/common/http';
import { WishlistService } from '../services/wishlist.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

  // @ViewChild('#recaptcha', {static: true }) recaptchaElement: ElementRef;

  public ID;
  public price;
  public message:string;
  public total_rate: Array<number> = [1, 2, 3, 4, 5];
  public rate: number;
  public colorAttributes: Array<any> = [];
	public sizeAttributes: Array<any> = [];
	public selectedColor;
	public selectedSize;
  public productDetail: Product;
	public selectedQty = 1;
  public product_slug: string;

  host = server_conf.host;
  home_url = server_conf.server;

  featured_products: Product;
  public sectionTitle = 'Featured Products';
  public seeAll = 'is_featured';

  constructor(private route: ActivatedRoute,public wishlist: WishlistService,public snackBar: MatSnackBar,public dialog: MatDialog,public cart: CartService,private Auth: AuthService,public spiner: NgxSpinnerService, private user: UserService,private router: Router,private http: HttpClient) { }

  ngOnInit() {

    this.http.get<Product>(this.host+'getproducts?is_featured=1').subscribe(featuredProducts => {
      if(featuredProducts.status){
        this.featured_products = featuredProducts.products.data;
      }
      else{
      }
    }
      ,err => {
    });

    const id = this.route.snapshot.paramMap.get('id');

    if(id){
      this.user.activate_user(id).subscribe((result:any) => {
        this.message = result.message;
      });


    }

  }

  // ngAfterViewInit(){
  //
  // setTimeout(()=>{
  //   this.loading = true;
  //   if (this.loading == true) {
  //     this.addRecaptchaScript();
  //   }
  // },5000);
  //
  //  }
  //
  //  renderReCaptch() {
  //    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
  //      'sitekey' : '6LePbq4UAAAAAPqwJU8u5g1Of1TIEMyoPpJQpyaD',
  //      'callback': (response) => {
  //          console.log(response);
  //      }
  //    });
  //  }
  //
  //  addRecaptchaScript() {
  //
  //    window['grecaptchaCallback'] = () => {
  //      this.renderReCaptch();
  //    }
  //
  //    (function(d, s, id, obj){
  //      var js, fjs = d.getElementsByTagName(s)[0];
  //      if (d.getElementById(id)) { obj.renderReCaptch(); return;}
  //      js = d.createElement(s); js.id = id;
  //      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
  //      fjs.parentNode.insertBefore(js, fjs);
  //    }(document, 'script', 'recaptcha-jssdk', this));
  //
  //  }

  addProductCart(slug){
  this.product_slug = slug;
  this.getProductDetail();
  }

  addToCart() {

    this.spiner.show();
    if (this.Auth.isLoggednIn()) {
      var attr = [];
      if ((this.colorAttributes && this.colorAttributes.length) && !this.selectedColor) {
        let config = new MatSnackBarConfig();
        config.duration = 7000;
        config.panelClass = ['red-snackbar'];
        config.verticalPosition = 'top';
        config.data = 'Please choose color.';
        this.snackBar.openFromComponent(MessageBarComponent, config);
        this.spiner.hide();
      } else if ((this.sizeAttributes && this.sizeAttributes.length) && !this.selectedSize) {
        let config = new MatSnackBarConfig();
        config.duration = 7000;
        config.panelClass = ['red-snackbar'];
        config.verticalPosition = 'top';
        config.data = 'Please select size.';
        this.snackBar.openFromComponent(MessageBarComponent, config);
        this.spiner.hide();
      } else if(this.cart.cartCount == 10) {
        let config = new MatSnackBarConfig();
        config.duration = 7000;
        config.panelClass = ['red-snackbar'];
        config.verticalPosition = 'top';
        config.data = 'Your cart is full. To add this product you need to remove any product from the cart.';
        this.snackBar.openFromComponent(MessageBarComponent, config);
        this.spiner.hide();
      } else {
        if (this.selectedColor) {
          for (var i = 0; i < this.colorAttributes.length; i++) {
            if (this.colorAttributes[i].id == this.selectedColor) {
              let temp = {
                'attribute_id': this.colorAttributes[i].attribute_id,
                'value': this.colorAttributes[i].value
              }
              attr.push(temp);
            }
          }
        }

        if (this.selectedSize) {
          for (var j = 0; j < this.sizeAttributes.length; j++) {
            if (this.sizeAttributes[j] && this.sizeAttributes[j].id == this.selectedSize) {
              let temp = {
                'attribute_id': this.sizeAttributes[j].attribute_id,
                'value': this.sizeAttributes[j].value
              }
              attr.push(temp);
            }
          }
        }
        //Add To Cart
        let data = {
          'user_id': this.Auth.getUser().id,
          'product_id': this.ID,
          'quantity': this.selectedQty,
          'price': this.price,
          'product_attributes': attr
        }

        this.cart.addToCart(data).subscribe((res: any) => {
          if (res.status) {
            let config = new MatSnackBarConfig();
            config.duration = 7000;
            config.panelClass = ['green-snackbar'];
            config.verticalPosition = 'top';
            config.data = 'Product has been added to your cart successfully.';
            this.snackBar.openFromComponent(MessageBarComponent, config);
            this.selectedColor = 0;
            this.selectedSize = 0;
            this.selectedQty = 1;
            if(!res.is_update) {
              this.cart.cartCount += 1;
              this.cart.cartTotal.next(this.cart.cartCount);
            }

            this.spiner.hide();
          } else {
            let config = new MatSnackBarConfig();
            config.duration = 7000;
            config.panelClass = ['red-snackbar'];
            config.verticalPosition = 'top';
            config.data = res.message;
            this.snackBar.openFromComponent(MessageBarComponent, config);
            this.selectedColor = 0;
            this.selectedSize = 0;
            this.selectedQty = 1;
            this.spiner.hide();
          }
        });
      }
    } else {
      this.spiner.hide();
      const dialog = this.dialog.open(SignInPopup, {
        width: '850px',
        data: {
          notRedirect: true
        }
      });
    }
  }

  getProductDetail() {
		this.spiner.show();
		this.http.get<Product>(this.host + "product-detail/" + this.product_slug).subscribe(productDetail => {
			if (productDetail.status) {


				this.spiner.hide();
				this.productDetail = productDetail.product;
				this.rate = productDetail.product.avg_rating;
				this.ID = productDetail.product.id;
				this.price = productDetail.product.price;
				//this.getReviews(productDetail.product.id);
				//this.getRelatedProducts(productDetail.product.product_associations);
				if (productDetail.product.attributes && productDetail.product.attributes.Color) {
					this.colorAttributes = productDetail.product.attributes.Color;
				}
				if (productDetail.product.attributes && productDetail.product.attributes.Size) {
					this.sizeAttributes = productDetail.product.attributes.Size;
				}

        		this.addToCart();
			} else {
				this.spiner.hide();
			}
		}, err => {
			console.log("Server error occured!");
		});
	}

  goToHome() {
    this.router.navigate(['/']);
  }

  checkRating(crate,rate): Boolean {
		return crate <= rate;
	}

	checkRatingHalf(crate,rate): Boolean {
		let old = crate - 1;
		return rate > old && rate < crate;
	}

  addToWishList(productId) {
    if (this.Auth.isLoggednIn()) {
      let user = JSON.parse(localStorage.getItem("user"));
      let data = {
        'user_id': user.id,
        'product_id': productId
      };
      this.spiner.show();

      this.wishlist.addToWishlist(data).subscribe((result: any) => {
        if (result.status) {
          let config = new MatSnackBarConfig();
          config.duration = 7000;
          config.panelClass = ['green-snackbar'];
          config.verticalPosition = 'top';
          config.data = "Product successfully added to wishlist.";
          this.snackBar.openFromComponent(MessageBarComponent, config);
          //this.productList();
          this.spiner.hide();
        } else {
          let config = new MatSnackBarConfig();
          config.duration = 7000;
          config.panelClass = ['red-snackbar'];
          config.verticalPosition = 'top';
          config.data = "Product already added to whishlist.";
          this.snackBar.openFromComponent(MessageBarComponent, config);
          this.spiner.hide();
        }

      }, err => {
        this.spiner.hide();
      });


    } else {
      this.spiner.hide();
      this.dialog.open(SignInPopup, {
        width: '850px',
        data: {
          notRedirect: true
        }
      });
    }

  }

}
