import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './router/app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { routingComponents } from './router/routerlist';
import { AppComponent } from './app.component';
import { HeaderComponent, SignInPopup, SignUpPopup, AussieDigitalLoginPopup } from './common-component/header/header.component';
import { FooterComponent } from './common-component/footer/footer.component';
import 'hammerjs';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MAT_SNACK_BAR_DATA, MatDialogModule, MatInputModule, MatFormFieldModule,MatCheckboxModule, MAT_LABEL_GLOBAL_OPTIONS, MatSelectModule, MatSnackBarModule} from '@angular/material';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { MessageBarComponent } from './common-component/message-bar/message-bar.component';
import { ActivateUserComponent } from './activate-user/activate-user.component';

import { ResetPasswordComponent } from './reset-password/reset-password.component';

import { AuthService } from './services/auth.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { ConfirmationPopupComponent } from './common-component/confirmation-popup/confirmation-popup.component';

import { DeleteAddressPopup } from '../app/my-account/delete-address/delete-address-popup';
import { MatButtonModule} from '@angular/material/button';
import { CookieService } from 'ngx-cookie-service';
import { NotificationComponent } from './notification/notification.component';
import { MatCardModule} from '@angular/material/card';
import { LazyLoadImagesModule } from 'ngx-lazy-load-images';
import { NotFoundComponent } from './not-found/not-found.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { AussieDigitalLoginComponent } from './aussie-digital-login/aussie-digital-login.component';

import { SubDomainInterceptor } from './services/interceptors.service';
import { BecomeASellerComponent } from './become-a-seller/become-a-seller.component';
import { PushNotificationService } from './services/pushnotification.service';
import { SafePipe } from './safe.pipe';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    routingComponents,
    SignInPopup,
    SignUpPopup,
    PrivacyPolicyComponent,
    MessageBarComponent,
    ActivateUserComponent,
    ResetPasswordComponent,
    ConfirmationPopupComponent,
    DeleteAddressPopup,
    NotificationComponent,
    NotFoundComponent,
    InvoiceComponent,
    AussieDigitalLoginComponent,
    AussieDigitalLoginPopup,
    BecomeASellerComponent,
    SafePipe
  ],

  imports: [
    LoadingBarRouterModule,
    BrowserAnimationsModule,
    MatDialogModule,
    BrowserModule,
    AppRoutingModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    HttpClientModule,
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatSnackBarModule,
    NgxSpinnerModule,
    InfiniteScrollModule,
    MatButtonModule,
    MatCardModule,
    LazyLoadImagesModule,
    ToastrModule.forRoot()
  ],

  entryComponents: [
    SignInPopup,
    SignUpPopup,
    MessageBarComponent,
    ConfirmationPopupComponent,
    DeleteAddressPopup,
    AussieDigitalLoginPopup
  ],

  providers: [
    AuthService,
    CookieService,
    PushNotificationService,
    {provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: {float: 'never'}},
    { provide: MAT_SNACK_BAR_DATA, useValue: {} },
    {provide: HTTP_INTERCEPTORS, useClass: SubDomainInterceptor, multi: true}
  ],

  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }

// platformBrowserDynamic().bootstrapModule(AppModule);
