import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public isLogged: Subject<boolean> = new Subject;
  private ls = localStorage;
  public user_name : Subject<string> = new Subject;

  constructor(){ 
    setTimeout(() => {
      if(this.isLoggednIn()){
        this.isLogged.next(true);
        if(this.getUser()) {
          this.user_name.next(this.getUser().full_name);
        }
      }else{
        this.isLogged.next(false);
      }    
    }, 2000);
  }

  setUser(accessToken: string) {
    localStorage.setItem("LoggedInUser", accessToken)
    this.isLogged.next(true);
    if(this.getUser()) {
      this.user_name.next(this.getUser().full_name);
    }
  }

  getUser() {
    let user = this.ls.getItem('user');
    return user ? JSON.parse(user) : null;
  }

  getToken() {
    return localStorage.getItem("LoggedInUser")
  }

  isLoggednIn() {
    return this.getToken() !== null;
  }

  logout() {
    localStorage.removeItem("LoggedInUser");
    localStorage.removeItem("user");
    this.isLogged.next(false);
    return true;
  }

  resetLogin(){
    localStorage.removeItem("LoggedInUser");
    localStorage.removeItem("user");
  }
}
