import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { server_conf } from '../app.config';
import { Faqs } from '../interface/faqs';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  host = server_conf.host;
  home_url = server_conf.server;
  faqs: Faqs;

  constructor( private http: HttpClient) { }

  ngOnInit() {
  		this.http.get<Faqs>(this.host+'get-faqs').subscribe( faqs =>{
  			if(faqs.status){
  				this.faqs = faqs.faqs;
  			}
  			else{
  				
  			}
  		},err => {
        
  		});

  }

  chechLog(){
    
  }

}
