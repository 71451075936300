import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { NgForm } from '@angular/forms';
import {  AuthService} from '../services/auth.service';

@Component({
  selector: 'app-aussie-digital-login',
  templateUrl: './aussie-digital-login.component.html',
  styleUrls: ['./aussie-digital-login.component.css']
})
export class AussieDigitalLoginComponent implements OnInit {

  data;
 
  constructor(
    private route: ActivatedRoute,
    private auth: AuthService,
    private user: UserService,
    private myRoute: Router
    ) { }

  ngOnInit() {
    if(this.auth.getUser()) {
      this.myRoute.navigate(["/"]);
    }
  }

  postLogin(form:NgForm) {
    let data = form.value;
    this.data = data;
    window.localStorage.setItem("aussie_digital_user", JSON.stringify(data));

    // window.set

    // window.opener.HandlePopupResult(data);

    // window.addEventListener('storage', function(event) {
    //   event.newValue == data;
    // });

    // window.addEventListener("aussie_digital_user", this.displayStorageEvent, true);

    // try {
    //   window.opener.HandlePopupResult(data);
    // }
    // catch (err) {
    //   console.log(err);
    // }
    // window.opener.HandlePopupResult(JSON.stringify(data));

    //API will hit here
    // window.close();
    return data;
  }

}
