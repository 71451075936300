import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar,MAT_SNACK_BAR_DATA, MatSnackBarConfig } from '@angular/material';
import {  NgxSpinnerService} from 'ngx-spinner';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MyAccountService } from '../services/my-account.service';
import { server_conf } from '../app.config';
import {  MessageBarComponent} from '../common-component/message-bar/message-bar.component';
@Component({
  selector: 'app-become-a-seller',
  templateUrl: './become-a-seller.component.html',
  styleUrls: ['./become-a-seller.component.css']
})
export class BecomeASellerComponent implements OnInit {
  public user;
  public countries: Array<any> = [];
  constructor(
    private account: MyAccountService,
    private auth: AuthService,
    private router: Router,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private http: HttpClient,
    private spinner : NgxSpinnerService) { }


  ngOnInit() {
    this.user = this.auth.getUser();
    this.getCountries();
    console.log(this.countries);
  }

  onSubmit(form) {
    this.spinner.show();
    form.value.user_id = this.user.id;
    this.account.storeSetupRequest(form.value).subscribe((res:any) => {
      if(res.status){
        //alert(res.message);
        form.resetForm();
        let config = new MatSnackBarConfig();
        config.duration = 7000;
        config.panelClass = ['green-snackbar'];
        config.verticalPosition = 'top';
        config.data = res.message;
        this.snackBar.openFromComponent(MessageBarComponent, config);
      }else{
        let config = new MatSnackBarConfig();
        config.duration = 7000;
        config.panelClass = ['red-snackbar'];
        config.verticalPosition = 'top';
        config.data = res.message;;
        this.snackBar.openFromComponent(MessageBarComponent, config);
      }
      this.spinner.hide();
      console.log(form.value);
    });
    //console.log(form.value);
  }

  getCountries() {
    this.account.getCountries().subscribe((res:any) => {
      if(res.status){
        console.log(res);
        this.countries = res.countries;
      }
    });
  }



}
