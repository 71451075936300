import { Component, OnInit } from '@angular/core';
import { Pages } from '../interface/pages';
import { HttpClient } from '@angular/common/http';
import { server_conf } from '../app.config'; 

@Component({
  selector: 'app-term-and-condition',
  templateUrl: './term-and-condition.component.html',
  styleUrls: ['./term-and-condition.component.css']
})
export class TermAndConditionComponent implements OnInit {

  constructor( private http: HttpClient ) { }

  host = server_conf.host;
  home_url = server_conf.server;
  pages: Pages;
  content = '';
  ngOnInit(): void {
  	this.http.get<Pages>(this.host+'cms/terms-and-conditions').subscribe( pages =>{
  		if(pages.status){
  			this.content = pages.page.content_text;
  		} 
  		else{
  			console.log('Data not found!');
  		}
  	}, err =>{
  		console.log('Server error occured!');
  	});
  }
}
